import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Title from "../components/title"
import ContactForm from "../components/contact-form"
import { Mail, MapPin, Phone } from "../components/icons"
import styles from "./Iletisim.module.scss"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Iletisim() {
  return (
    <>
      <SEO title="İletişim" />
      <Layout>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.927240515576!2d28.665925299999998!3d41.0049657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f820b5a5f5d%3A0x8783628054e4392d!2zWWFrdXBsdSwgNjMuIFNrLiBObzoyMCBEOjMzLCAzNDUyNCBCZXlsaWtkw7x6w7wvxLBzdGFuYnVsLCBUw7xya2l5ZQ!5e0!3m2!1sen!2suk!4v1721731198473!5m2!1sen!2suk"
          width="100%"
          height="450"
          frameborder="0"
          className={styles.map}
          title="harita"
        ></iframe>

        <Container className={styles.container}>
          <div>
            <Title headingLevel="h3">İletişim Formu</Title>
            <p>
              Aşağıdaki formu kullanarak bize istek, öneri ve şikayetlerinizi
              iletebilirsiniz.
            </p>
            <ContactForm />
          </div>
          <div>
            <Title headingLevel="h3">İletişim Bilgileri</Title>
            <p>
              AFFA İstanbul İş Güvenliği Laboratuvar Belgelendirme Eğitim ve
              Mühendislik A.Ş.
            </p>
            <div className={styles.iconBoxes}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div>
                  <MapPin /> <strong>Adres: </strong>{" "}
                  <a
                    href="https://www.google.com/maps?ll=41.004966,28.665925&z=16&t=m&hl=en&gl=GB&mapclient=embed&q=63.+Sk.+No:20+D:33+Yakuplu+34524+Beylikd%C3%BCz%C3%BC/%C4%B0stanbul+T%C3%BCrkiye"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Yakuplu Mah. 63. Sokak No: 20A/33, Beylikdüzü/İstanbul,
                    Türkiye
                  </a>
                </div>

                <StaticQuery
                  query={query}
                  render={data => (
                    <Img
                      style={{
                        width: "80px",
                        height: "80px",
                        flex: "none",
                        alignSelf: "flex-start",
                      }}
                      fixed={data.adresQR.sharp.fixed}
                      alt="Adres QR"
                    />
                  )}
                />
              </p>

              <p>
                <Phone /> <strong>Tel: </strong>
                <a href="tel:+902128711060">+90 212 871 1060</a>
              </p>
              <p>
                <Phone /> <strong>Tel: </strong>
                <a href="tel:+908504955606">+90 850 495 5606</a>
              </p>
              <p>
                <Mail /> <strong>E-Posta: </strong>
                <a href="mailto:info@affakalite.com.tr">
                  info@affakalite.com.tr
                </a>
              </p>
            </div>
          </div>
        </Container>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    adresQR: file(relativePath: { eq: "adres-qr.jpg" }) {
      sharp: childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
